// extracted by mini-css-extract-plugin
export var stories = "StoriesList-module--stories--2dBzz";
export var stories_list = "StoriesList-module--stories_list--3vmqa";
export var dial = "StoriesList-module--dial--1s2fe";
export var led = "StoriesList-module--led--S8a6P";
export var screen = "StoriesList-module--screen--2R1gS";
export var title = "StoriesList-module--title--1txzQ";
export var gatsby_wrapper = "StoriesList-module--gatsby_wrapper--1OSld";
export var screen_and_controls = "StoriesList-module--screen_and_controls--2yTWG";
export var controls = "StoriesList-module--controls--1kYbE";
export var led_container = "StoriesList-module--led_container--2BbPe";
export var vent = "StoriesList-module--vent--Psvzh";
export var screenContainer = "StoriesList-module--screenContainer--2FoRS";